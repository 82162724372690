$custom_bg_image:'../images/bg.jpg';;
$prj_main_color:#191919;;
$enable-responsive-font-sizes: true;
//$fa-font-path: "../webfonts";
$fa-font-path: "../vendor/fa";

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
// @import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
// @import "node_modules/bootstrap/scss/grid";


// Fontawesome
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/@fortawesome/fontawesome-free/scss/v4-shims";

@import 'node_modules/brand-colors/dist/latest/scss/brand-colors.latest.scss';

/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
@import 'node_modules/include-media/dist/include-media';
/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/

// STATIC
body,html
{
	height: 100%;
}

.btn-circle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.btn-circle i {
  position: relative;
  top: -1px;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

.speed-menu-bg
{
	/* Location of the image */
	background-image:url($custom_bg_image);
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover; 
	//height: 100%;
}

.card
{
	border-radius:15px !important;
}

.speedmenu-fonts-info
{
	font-family: 'Muli', sans-serif;
}

.speedmenu-h1 {
		color: #333;
		font-weight: 800;
	}

.speedmenu-h5 {
		color: #333;
		font-weight: 800;
	}

.speedmenu-p {
		color: #666;
		font-weight: 600;
	}

/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
// LA VARIABILE $prj_main_color VIENE CREATA DA GULPFILE.JS A PARTIRE DAL FILE conf.json
/*---------------------------------------------------------------------------------------------------*/
.speed-menu-responsive-my-2
{
	@include media(">=phone", "<=tablet","retina2x")
	{
		margin-top: map-get($spacers,2);
		margin-bottom: map-get($spacers,2);
	}
	@include media(">=phone", "<=tablet","retina3x")
	{
		margin-top: map-get($spacers,2);
		margin-bottom: map-get($spacers,2);
	}
}

.speed-menu-border-radius
{
	border-radius:15px 0px 0px 15px;

	@include media(">=phone", "<=tablet","retina2x")
	{
		border-radius:15px 15px 0px 0px;
	}

	@include media(">=phone", "<=tablet","retina3x")
	{
		border-radius:15px 15px 0px 0px;
	}
}
.speed-menu-logo-size
{
	max-width:90%;

	@include media(">=phone", "<=tablet","retina2x")
	{
		max-width:50%;
	}
	@include media(">=phone", "<=tablet","retina3x")
	{
		max-width:50%;
	}

	@include media(">=tablet", "<=desktop","retina2x")
	{
		max-width:60%;
	}
	@include media(">=tablet", "<=desktop","retina3x")
	{
		max-width:60%;
	}
}

.speed-menu-link {
	color: #000;//$prj_main_color;
}
.speed-menu-link:hover, .speed-menu-style:focus {
	color: lighten(#000, 50%);
	text-decoration: none;
}

.speedmenu-btn {
border: none;
background:$prj_main_color;
color: #fff !important;
font-weight: 700;
padding: 20px;
text-transform: uppercase;
border-radius: 6px;
display: inline-block;
transition: all 0.3s ease 0s;
outline: none;
}

.speedmenu-btn:hover, .speedmenu-btn.focus, .speedmenu-btn:focus {
	outline: none;
	box-shadow: none!important;
}

.speedmenu-btn:hover {
color: #fff !important;
font-weight: 700 !important;
letter-spacing: 3px;
background: darken($prj_main_color, 50%);
-webkit-box-shadow: 0px 5px 40px -10px rgba($prj_main_color,0.57);
-moz-box-shadow: 0px 5px 40px -10px rgba($prj_main_color,0.57);
transition: all 0.3s ease 0s;
}

.social-fb-color{
	color:$white;
	background-color:$bc-facebook;
}
.social-ig-color{
	color:$white;
	background-color:$bc-instagram-5;
}
.qrcode-icon-color{
	color:$white;
	background-color:$prj_main_color;
}
.fa-barcode {
  color: $white;
}
.custom-footer-background-color
{
	background-color: rgba($white, .54);
}
/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------*/
/* swiperjs remapping */
.swiper-container 
{
	width: 100%;
	/*height: 80%;*/
}
.swiper-slide {
	text-align: center;
	font-size: 1.2em;
	display: flex;
	align-items: center;
}

.swiper-slide img {
	width: 100%;
	margin: auto;
}


@media screen and (orientation:landscape) {
	.swiper-container {
		width: inherit;
	}
}

.swiper-slide img {
	max-width: 600px;
}

/*
.scroll-home {
	margin: 10% 15%
}
*/

.swiper-button:after {
	color: rgba($white, .54);
}

.swiper-pagination {
	position: unset;
	margin-top: 10px;
	margin-bottom: 20px;
}

.swiper-pagination-bullet {
	width: 1.3em;
	height: 1.3em;
	margin-right: 10px;
}

.swiper-pagination-bullet-active
{
	background:$prj_main_color;
}

.swiper-lazy-preloader {
	margin-top: 40px;
}

.append-buttons {
      text-align: center;
      margin-top: 20px;
    }
.append-buttons a {
  display: inline-block;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
}